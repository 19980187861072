<template>
  <div class="confirm-pay">
    <template v-if="!payStatus">
      <div class="pay-time">
        <p>剩余支付时间</p>
        <van-count-down v-if="time > 0" :time="time" format="mm:ss" />
         <template v-else>
            <p>交易支付超时，订单已关闭</p>
          </template>
      </div>
      <div class="pay-detail">
        <div class="flex">
          <template v-if="detail.orderItems.length">
            <p>{{detail.orderItems[0].productName}}</p>
            <span class="bold">￥{{detail.paymentAmount}}</span>
          </template>
         
        </div>
        <div class="flex">
          <p>优惠券抵扣</p>
          <span style="color: #6E7886">暂无优惠</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pay-img">
        <img :src="appointFailImg" alt="">
      </div>
      <p class="pay-fail-word">{{!payStatus ? '支付失败，请重新支付！' : '支付成功，预约失败！'}}</p>
    </template>
    <div class="buy-btn">
      <div class="call-tip" v-if="!payStatus && !appointmentStatus">
        <span style="color:#6E7886;margin-right: 8px">总价</span>
        <span class="red bold" style="font-size: 20px">￥{{detail.paymentAmount}}</span>
      </div>
      <div class="detail-price">
        <!-- <template v-if="!payStatus">
          <van-button round @click="$router.push('/home')">返回首页</van-button>
          <van-button type="primary" round @click="handlerPay" v-loading='loading'>重新支付</van-button>
        </template> -->
        <template v-if="payStatus">
          <van-button round @click="handlerRefond">立即退款</van-button>
          <van-button type="primary" round @click="$router.push('/edit-appointment/'+orderNo)">重新选择日期</van-button>
        </template>
        <van-button v-if="!payStatus && !appointmentStatus && time > 0" type="primary" round @click="handlerPay">支付并预约</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Repay, QueryStatus, GetOrderItem } from '@/api'
export default {
  name: 'confirm-pay',
  data () {
    return {
      orderNo: '',
      payInfo: {},
      isPay: true,
      payFailImg: require('@/assets/icon_img_x@2x.png'),
      appointFailImg: require('@/assets/icon_img_x2@2x.png'),
      appointmentStatus: false,
      payStatus: false,
      payInfo: {},
      loading: false,
      detail: {orderItems: []},
      createdTime: ''
    }
  },
  computed: {
    time () {
      let current
      console.log(this.detail.createDate)
      if (this.detail.createDate) {
        current = new Date(this.detail.createDate.replace(/-/g, "/")).getTime()
        console.log(current, 'current')
      }
      const now = new Date().getTime()
      let time
      if (current && !this.createdTime) {
        time = 30 * 60 * 1000 - now + current
      } else {
        time = 30 * 60 * 1000
      }
      return time
    }
  },
  created () {
    if (this.$route.query.createdTime) {
      this.createdTime = this.$route.query.createdTime
    }
  },
  mounted () {
    this.$setTitle('支付确认')
    this.$store.commit('setTitle', '支付确认')
    this.orderNo = this.$route.params.id
    this.appointmentStatus = this.$route.query.appointmentStatus === 'false' ? 0 : 1
    this.payStatus = this.$route.query.payStatus === 'false' ? 0 : 1
    this.getDetail()
  },
  methods: {
    async handlerPay () {
      this.loading = true
      let data = await Repay({orderNo: this.orderNo})
      this.loading = false
      this.payInfo = data
      this.weixinPay()
      // this.$router.push('./')
    },
    handlerRefond () {
      this.$router.push('/refund-detail/'+this.orderNo)
    },
    onBridgeReady(){
      const _this = this
      console.log(2)
      console.log(_this.payInfo)
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
            "appId": _this.payInfo.appId,     //公众号名称，由商户传入     
          "timeStamp": _this.payInfo.timestamp,         //时间戳，自1970年以来的秒数     
          "nonceStr": _this.payInfo.nonceStr, //随机串     
          "package": _this.payInfo.prepayIdPackage,     
          "signType": 'RSA',         //微信签名方式：     
          "paySign": _this.payInfo.signature //微信签名 
        },
        
        async (res) => {
          let {payStatus, appointmentStatus} = await this.QueryStatus()
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            if (appointmentStatus) {
              _this.$router.push('/appointment-detail/'+_this.orderNo)
            } else {
              this.appointmentStatus = appointmentStatus
            }
            _this.$toast('支付成功')
            // this.gametwoApplyWx()
            // webPayReturnToOrder({gameOrderId: _this.payInfo.gameOrderId})
            // _this.$router.push('/success')
          // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            console.log(res.err_msg, 'wx_pay')
            _this.$toast('支付失败,请重新支付')
            _this.appointmentStatus = appointmentStatus
            _this.payStatus = payStatus
          }
      })
    },
    weixinPay() {
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        }else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady); 
            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      }else{
        this.onBridgeReady();
      }
    },
    async QueryStatus () {
      let data = await QueryStatus({orderNo: this.orderNo})
      return data
    },
    async getDetail () {
      let data = await GetOrderItem({orderNo: this.orderNo})
      this.detail = data
      if (this.detail.orderItems.length) {
         this.detail.orderItems = this.detail.orderItems.map(item => {
           item.productJson = item.productJson ? JSON.parse(item.productJson) : {}
           return item
         })
      }
      console.log(this.detail)
    }
  }
}
</script>

<style lang="scss">
.confirm-pay{
  .pay-time{
    margin: 43px 0;
    p{
      color: #6E7886;
      font-size: 15px;
      text-align: center;
      margin-bottom: 14px;
    }
    .van-count-down{
      color: #313237;
      font-size: 32px;
      text-align: center;
      font-weight: bold;
      line-height: 1.4;
    }
  }
  .pay-detail{
    border-radius: 10px;
    margin: 0 15px;
    background-color: #F5F5F5;
    padding: 0 16px;
    .flex{
      padding: 18px 0;
      font-size: 15px;
      color: #131828;
      >p{
        flex: 1;
      }
      &+.flex{
        border-top: 1px #DCDCDC solid;
      }
    }
  }
  .pay-img{
    width: 143px;
    margin: 30px auto;
  }
  .pay-fail-word{
    color: #FF0F0F;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
}
</style>